import store from '../store/index.js'

function hasPermission(key) {
    const roles = store.getters.roles;
    return (isHavePermission(roles, key));
}

function isHavePermission(roles, key) {
    if (roles && roles.length > 0) {
        let listPermissions = [];
        let permissions = [];
        roles.map(e => {
            listPermissions = e.Permissions;
            listPermissions.map(element => permissions.push(element.slug.trim()));
        });

        return permissions.indexOf(key) !== -1;
    } else {
        return false;
    }
}

function hasRole(key) {
    const roles = store.getters.roles;
    if (roles && roles.length > 0) {
        let listRoles = [];
        roles.map(e => {
            listRoles.push(e.slug.trim())
        });

        return listRoles.indexOf(key) !== -1;
    } else {
        return false;
    }
}

export {hasPermission,isHavePermission, hasRole}
