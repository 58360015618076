import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import {publicPath} from '../../vue.config'
import {hasPermission, hasRole} from "@/services/globalFunction";


Vue.use(VueRouter);

const userRoutes = [{
    path: '/', name: 'Home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true}, // beforeEnter(to, from, next) {
}, {
    path: '/login', alias: '/', name: 'Login', component: () => import('../views/Login.vue')
}, {
    // catch all 404 - define at the very end
    path: "*", component: () => import('../views/404.vue')
},];

const subEngRoutes = [{
    path: '/', name: 'Home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true}, // beforeEnter(to, from, next) {
}, {
    path: '/login', alias: '/', name: 'Login', component: () => import('../views/Login.vue')
}, {
    path: '/profile', name: 'Profile', component: () => import('../views/eng/Profile.vue'), meta: {
        requiresAuth: true
    }
}, {
    // catch all 404 - define at the very end
    path: "*", component: () => import('../views/404.vue')
},];

const engRoutes = [{
    path: '/', name: 'Home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true, permission: 'accessControl'},
},

    {
        path: '/profile', name: 'Profile', component: () => import('../views/eng/Profile.vue'), meta: {
            requiresAuth: true
        }
    },

    {
        path: '/jobs', component: () => import('../views/eng/Jobs.vue'), meta: {
            requiresAuth: true
        }
    },

    {
        path: '/available', name: 'Available', component: () => import('../views/eng/Available.vue'), meta: {
            requiresAuth: true
        }
    }, {
        // catch all 404 - define at the very end
        path: "*", component: () => import('../views/404.vue')
    }];

const adminRoutes = [{
    path: '/', name: 'Home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true, permission: 'accessControl'},
},

    {
        path: '/profile/:id?', name: 'Profile', component: () => import('../views/eng/Profile.vue'), meta: {
            requiresAuth: true, permission: 'showProfile'
        }
    },

    {
        path: '/jobs', component: () => import('../views/eng/Jobs.vue'), meta: {
            requiresAuth: true, permission: 'findAllJobs'
        }
    },

    {
        path: '/available', name: 'Available', component: () => import('../views/eng/Available.vue'), meta: {
            requiresAuth: true, permission: 'findAllAvailableEng'
        }
    },

    {
        path: '/users', name: 'Users', component: () => import('../views/Users.vue'), meta: {
            requiresAuth: true, permission: 'findAllUsers'
        }
    },

    {
        path: '/care/planServices', component: () => import('../views/care/PlanServices.vue'), meta: {
            requiresAuth: true, permission: 'findAllPlanServices'
        },
    },{
        path: '/care/planUsers', component: () => import('../views/care/PlanUsers.vue'), meta: {
            requiresAuth: true, permission: 'findAllPlanUsers'
        },
    },{
        path: '/care/planAppliances', component: () => import('../views/care/PlanAppliance.vue'), meta: {
            requiresAuth: true, permission: 'findAllPlanAppliances'
        },
    },

    {
        path: '/settings/cities', component: () => import('../views/settings/Cities.vue'), meta: {
            requiresAuth: true, permission: 'findAllCities'
        },
    },

    {
        path: '/settings/manufacturers', component: () => import('../views/settings/Manufacturers.vue'), meta: {
            requiresAuth: true, permission: 'findAllManufacturers'
        },
    },
    {
        path: '/settings/emailTemplates', component: () => import('../views/settings/EmailTemplates.vue'), meta: {
            requiresAuth: true, permission: 'findAllEmailTemplates'
        },
    }, {
        path: '/settings/plans', component: () => import('../views/settings/Plans.vue'), meta: {
            requiresAuth: true, permission: 'findAllPlans'
        },
    }, {
        path: '/localServices/jobCategories', component: () => import('../views/localServices/JobCategories.vue'), meta: {
            requiresAuth: true, permission: 'findAllJobCategories'
        },
    }, {
        path: '/localServices/customers', component: () => import('../views/localServices/Customers.vue'), meta: {
            requiresAuth: true, permission: 'findAllCustomers'
        },
    }, {
        path: '/localServices/calendar', component: () => import('../views/localServices/Calendar.vue'), meta: {
            requiresAuth: true, permission: 'findAllServices'
        },
    }, {
        path: '/localServices/services', component: () => import('../views/localServices/Services.vue'), meta: {
            requiresAuth: true, permission: 'findAllServices'
        },
    }, {
        path: '/products', component: () => import('../views/products/Products.vue'), meta: {
            requiresAuth: true, permission: 'findAllProducts'
        },
    },

    {
        path: '/boilers/addons', component: () => import('../views/products/AddonProducts.vue'), meta: {
            requiresAuth: true, permission: 'findAllAddons'
        },
    }, {
        path: '/settings/fuelTypes', component: () => import('../views/settings/FuelTypes.vue'), meta: {
            requiresAuth: true, permission: 'findAllFuelTypes'
        },
    },

    {
        path: '/settings/boilerTypes', component: () => import('../views/settings/BoilerTypes.vue'), meta: {
            requiresAuth: true, permission: 'findAllBoilerTypes'
        },
    },

    {
        path: '/settings/sizes', component: () => import('../views/settings/Sizes.vue'), meta: {
            requiresAuth: true, permission: 'findAllSizes'
        },
    },

    {
        path: '/settings/prices', component: () => import('../views/settings/Prices.vue'), meta: {
            requiresAuth: true, permission: 'findAllPrices'
        },
    },

    {
        path: '/settings/imageTypes', component: () => import('../views/settings/ImageTypes.vue'), meta: {
            requiresAuth: true, permission: 'findAllImageTypes'
        },
    },

    {
        path: '/settings/skills', component: () => import('../views/settings/Skills.vue'), meta: {
            requiresAuth: true, permission: 'findAllSkills'
        },
    },

    {
        path: '/settings/addons', component: () => import('../views/settings/Addons.vue'), meta: {
            requiresAuth: true, permission: 'findAllAddons'
        },
    }, {
        path: '/settings/categories', component: () => import('../views/settings/Categories.vue'), meta: {
            requiresAuth: true, permission: 'findAllCategories'
        },
    },

    {
        path: '/settings/postcodes', component: () => import('../views/settings/Postcode.vue'), meta: {
            requiresAuth: true, permission: 'findAllPostcodes'
        },
    },

    {
        path: '/login', alias: '/', name: 'Login', component: () => import('../views/Login.vue')
    },

    {
        path: '/settings/questions', component: () => import('../views/settings/Questions.vue'), meta: {
            requiresAuth: true, permission: 'findAllQuestions'
        },
    }, {
        path: '/orders', component: () => import('../views/Orders.vue'), meta: {
            requiresAuth: true, permission: 'findAllOrders'
        },
    }, {
        path: '/quotes', component: () => import('../views/Quotes.vue'), meta: {
            requiresAuth: true, permission: 'findAllQuotes'
        },
    }, {
        path: '/settings/installQuestions', component: () => import('../views/settings/InstallQuestions.vue'), meta: {
            requiresAuth: true, permission: 'findAllInstallQuestions'
        },
    },

    {
        path: '/settings/installAvailables', component: () => import('../views/settings/installAvailables.vue'), meta: {
            requiresAuth: true, permission: 'findAllAvailableInstallations'
        },
    },

    {
        path: '/contactus/contacts', component: () => import('../views/contactUs/Contacts.vue'), meta: {
            requiresAuth: true, permission: 'findAllContacts'
        },
    },

    {
        path: '/contactus/subjects', component: () => import('../views/contactUs/Subjects.vue'), meta: {
            requiresAuth: true, permission: 'findAllSubjects'
        },
    },

    {
        path: '/settings/coupons', component: () => import('../views/settings/Coupons.vue'), meta: {
            requiresAuth: true, permission: 'findAllCoupons'
        },
    }, {
        path: '/settings/roles', name: 'Roles', component: () => import('../views/settings/Roles.vue'), meta: {
            requiresAuth: true, permission: 'findAllRoles'
        }
    }, {
        path: '/settings/permissions', name: 'Permissions', component: () => import('../views/settings/Permissions.vue'), meta: {
            requiresAuth: true, permission: 'findAllPermissions'
        }
    }, {
        path: '/settings/options', name: 'Options', component: () => import('../views/settings/Options.vue'), meta: {
            requiresAuth: true, permission: 'findAllOptions'
        }
    }, {
        path: "/403", component: () => import('../views/403.vue')
    }, {
        // catch all 404 - define at the very end
        path: "*", component: () => import('../views/404.vue')
    }];

const router = new VueRouter({
    base: process.env.BASE_URL + publicPath, routes: adminRoutes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if (hasPermission(to.meta.permission)) {
                next();
            } else {
                next('/403');
            }
        } else {
            sessionStorage.setItem('redirectPath', to.path);
            next('/login');
        }

    } else {

        if (hasRole("engineer") && engRoutes.filter(e => e.path === to.path).length < 1) {
            next('/404')
        } else if (!hasRole("engineer") && !hasRole("admin") && subEngRoutes.filter(e => e.path === to.path).length < 1) {
            next('/404')
        } else if (!hasRole("engineer") && !hasRole("admin") && userRoutes.filter(e => e.path === to.path).length < 1) {
            next('/404')
        } else {
            next()
        }
    }
});


export default router
