import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import networks from '../services/networks';
import {appendPassword, dialCode, jwtAuthorization, urlLogin, urlRegister} from "@/config";
import firebase from "firebase";
import {isHavePermission} from "@/services/globalFunction";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: null, token: null, user: null, isShowDeleted: false, isForceDeleted: false, roles: [],
    }, mutations: {
        auth_request(state) {
            state.status = 'loading'
        }, auth_success(state, payload) {
            state.status = 'success';
            state.token = payload.token;
            state.user = payload.user;

        }, auth_error(state) {
            state.status = 'error';
        }, logout(state) {
            state.status = null;
            state.token = null;
            state.user = null;
            firebase.auth().signOut();
        },
    }, actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');

                let username = user.phoneNumber.split(user.dialCode)[1];
                let userInfo, urlProcess;
                if (user.processType === 0) {
                    urlProcess = urlLogin;
                    userInfo = {"username": "0" + username, "password": user.uid + appendPassword};
                } else {
                    urlProcess = urlRegister;
                    userInfo = {
                        "username": "0" + username,
                        "password": user.uid + appendPassword,
                        "name": user.name,
                        "dialCode": user.dialCode,
                        "email": user.email
                    };
                }


                networks.fetchFromWeb(urlProcess, 1, userInfo)
                    .then(response => {

                        const token = response.data.token;
                        const user = response.data.user;
                        const roles = user.Roles;

                        if (isHavePermission(roles, "accessControl")) {
                            axios.defaults.headers.common['Authorization'] = jwtAuthorization + token;
                            commit('auth_success', {user: user, token: token});
                            resolve();
                        } else {
                            commit('logout');
                            commit('auth_error');
                            resolve(reject({errors: "you don't have permission to access this page"}));
                        }


                    })
                    .catch(error => {
                        commit('logout');
                        commit('auth_error');
                        if (error.response.status === 401) {
                            resolve(reject({errors: "Sorry , you dont have un account please create new one."}));
                        } else {
                            reject(error);
                        }

                    });

            })
        },

        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                delete axios.defaults.headers.common['Authorization'];
                resolve()
            })
        },

        getUserFromApi({commit}, user) {
            return new Promise((resolve, reject) => {
                let username = user.phoneNumber.split(dialCode)[1];
                let userInfo = {"username": "0" + username, "password": user.uid + appendPassword};

                networks.fetchFromWeb(urlLogin, 1, userInfo)
                    .then(response => {
                        const token = response.data.token;
                        const user = response.data.user;
                        const roles = user.Roles;

                        if (isHavePermission(roles, "accessControl")) {
                            axios.defaults.headers.common['Authorization'] = jwtAuthorization + token;
                            commit('auth_success', {user: user, token: token});
                            resolve();
                        } else {
                            commit('logout');
                            commit('auth_error');
                            resolve(reject({errors: "you don't have permission to access this page"}));
                        }


                    })
                    .catch(error => {
                        commit('logout');
                        commit('auth_error');
                        reject(error.response.data)
                    });

            });
        },

    }, getters: {
        isLoggedIn: state => !!state.token,
        token: state => state.token,
        authStatus: state => state.status,
        user: state => state.user,
        isShowDeleted: state => state.isShowDeleted,
        isForceDeleted: state => state.isForceDeleted,
        roles: state => state.user?.Roles ?? [],
    }
})
