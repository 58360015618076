<template>
  <v-app class="my-application">
    <v-navigation-drawer v-if="isLoggedIn" v-model="drawer"
                         app
                         color="#082d3c"
                         dark
                         left
    >
      <v-list rounded>
        <v-list-item :class="'px-2'" two-line>
          <v-list-item-avatar>
            <img alt="" src="@/assets/pixel.png">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Pixel Company</v-list-item-title>
            <v-list-item-subtitle>Version 1.0.0</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Main</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('findAllUsers')" link to="/users">
          <v-list-item-action>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('engineer')" link to="/profile">
          <v-list-item-action>
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('engineer')" link to="/jobs">
          <v-list-item-action>
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Jobs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('engineer')" link to="/available">
          <v-list-item-action>
            <v-icon>mdi-folder-question-outline</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>My Available</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="hasPermission('findAllCustomers') || hasPermission('findAllServices') || hasPermission('findAllJobCategories')"
            color="white"
            group="/localServices"
            no-action
            prepend-icon="mdi-toolbox-outline"
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Local Services</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link v-if="hasPermission('findAllCustomers')" to="/localServices/customers">

            <v-list-item-content>
              <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllServices')" to="/localServices/services">

            <v-list-item-content>
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllJobCategories')" to="/localServices/jobCategories">

            <v-list-item-content>
              <v-list-item-title>Job Categories</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllCalendar')" to="/localServices/calendar">

            <v-list-item-content>
              <v-list-item-title>Calendar Booking</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-if="hasPermission('findAllProducts')" link to="/products">
          <v-list-item-action>
            <v-icon>mdi-check-decagram-outline</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('findAllOrders')" link to="/orders">
          <v-list-item-action>
            <v-icon>mdi-store</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasPermission('findAllQuotes')" link to="/quotes">
          <v-list-item-action>
            <v-icon>mdi-cart-check</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Quotes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="hasPermission('findAllContacts') || hasPermission('findAllSubjects')"
            color="white"
            group="/contactus"
            no-action
            prepend-icon="mdi-card-account-phone-outline"
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item link to="/contactus/contacts" v-if="hasPermission('findAllContacts')">
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/contactus/subjects" v-if="hasPermission('findAllSubjects')">
            <v-list-item-content>
              <v-list-item-title>Subjects</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-group
            v-if="hasPermission('findAllPlanServices') || hasPermission('findAllPlanUsers')"
            color="white"
            group="/care"
            no-action
            prepend-icon="mdi-tools"
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Care Services</v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item link v-if="hasPermission('findAllPlanServices')" to="/care/planServices">

            <v-list-item-content>
              <v-list-item-title>Plan Services</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllPlanUsers')" to="/care/planUsers">

            <v-list-item-content>
              <v-list-item-title>Plan Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        <v-list-item link v-if="hasPermission('findAllPlanAppliance')" to="/care/planAppliances">

            <v-list-item-content>
              <v-list-item-title>Plan Appliance</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>


        <v-list-group
            v-if="isShowTabSetting()"
            color="white"
            group="/settings"
            no-action
            prepend-icon="mdi-cog-outline"
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item link v-if="hasPermission('findAllAddons')" to="/settings/addons">

            <v-list-item-content>
              <v-list-item-title>Addons</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllCategories')" to="/settings/categories">

            <v-list-item-content>
              <v-list-item-title>Addons Categories</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllFuelTypes')" to="/settings/fuelTypes">

            <v-list-item-content>
              <v-list-item-title>Fuel Types</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllBoilerTypes')" to="/settings/boilerTypes">

            <v-list-item-content>
              <v-list-item-title>Boiler Types</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllSizes')" to="/settings/sizes">
            <v-list-item-content>
              <v-list-item-title>Sizes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllPrices')" to="/settings/prices">
            <v-list-item-content>
              <v-list-item-title>Prices</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllCities')" to="/settings/cities">
            <v-list-item-content>
              <v-list-item-title>Cities</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllPostcodes')" to="/settings/postcodes">
            <v-list-item-content>
              <v-list-item-title>Postcodes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllManufacturers')" to="/settings/manufacturers">
            <v-list-item-content>
              <v-list-item-title>Manufacturers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllEmailTemplates')" to="/settings/emailTemplates">
            <v-list-item-content>
              <v-list-item-title>Email Templates</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllPlans')" to="/settings/plans">
            <v-list-item-content>
              <v-list-item-title>Plans</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllImageTypes')" to="/settings/imageTypes">
            <v-list-item-content>
              <v-list-item-title>Images Types</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllQuestions')" to="/settings/questions">
            <v-list-item-content>
              <v-list-item-title>System Questionnaire</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllInstallQuestions')" to="/settings/installQuestions">
            <v-list-item-content>
              <v-list-item-title>Install Questions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllAvailableInstallations')" to="/settings/installAvailables">
            <v-list-item-content>
              <v-list-item-title>Install Calendar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllCoupons')" to="/settings/coupons">

            <v-list-item-content>
              <v-list-item-title>Coupons</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllSkills')" to="/settings/skills">

            <v-list-item-content>
              <v-list-item-title>Engineers Skills</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllPermissions')" to="/settings/permissions">

            <v-list-item-content>
              <v-list-item-title>Permissions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllRoles')" to="/settings/roles">

            <v-list-item-content>
              <v-list-item-title>Roles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-if="hasPermission('findAllOptions')" to="/settings/options">

            <v-list-item-content>
              <v-list-item-title>Options</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="isLoggedIn"
               app
               color="#082d3c"
               dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>LetsHeat Company</v-toolbar-title>


    </v-app-bar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>

    </v-main>
  </v-app>
</template>

<script>
import {hasPermission} from "@/services/globalFunction";

export default {

    data: () => ({
      drawer: null,
    }),

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },

    methods: {
      hasPermission,
      logout: function () {
        this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            });
      },
      isShowTabSetting() {
        return hasPermission('findAllAddons') || hasPermission('findAllCategories') || hasPermission('findAllFuelTypes') || hasPermission('findAllBoilerTypes') || hasPermission('findAllSizes') || hasPermission('findAllPrices') || hasPermission('findAllCities') || hasPermission('findAllPostcodes') || hasPermission('findAllManufacturers') || hasPermission('findAllEmailTemplates') || hasPermission('findAllPlans') || hasPermission('findAllImageTypes') || hasPermission('findAllQuestions') || hasPermission('findAllInstallQuestions') || hasPermission('findAllInstallAvailables') || hasPermission('findAllCoupons') || hasPermission('findAllSkills') || hasPermission('findAllPermissions') || hasPermission('findAllRoles') || hasPermission('findAllOptions');

      },

    },

  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,800');

$font-family: 'Open Sans', serif;
.my-application {
  .headline,
  [class*='display-'],
  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }

  font-family: $font-family, sans-serif !important;
}

.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  font-size: 0.8rem;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px;
}

.v-icon.v-icon {
  font-size: 18px;
}

.my-application .headline, .my-application [class*=display-], .my-application [class*=text-] {
  color: #000000;
}

.deletedRow {
  background-color: rgb(255 0 0 / 10%);
}
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .1s;

}

.fade-enter-active {
  transition-delay: .1s;
 }

.fade-enter, .fade-leave-active {
   opacity: 0
}
</style>
