import firebase from "../services/firestore";
import store from "../store";
import router from "../router";

firebase.auth().onAuthStateChanged(user => {
    // if (user != null && store.state.status !== 'loading') {
    //     store.dispatch("getUserFromApi", user)
    // }
    if (user != null)
        store.dispatch("getUserFromApi", user).then(() => router.push(sessionStorage.getItem('redirectPath')||"/"))

});
