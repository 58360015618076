module.exports = {
    transpileDependencies: [
        "vuetify","@peepi/vuetify-tiptap"
    ],
    publicPath: process.env.NODE_ENV === 'production'
        ? '/'
        : '/',

    devServer: {
        allowedHosts: ['.pixel-iq.com'],
        public: '0.0.0.0',
        port: 8081,
    }
};
