export const backendUrl = "https://api.letsheat.co.uk";   // "http://localhost:8080" OR "https://api.letsheat.co.uk"
export const apiPath = "";                            // set "" if you run on (Dev Mode/without folder) or "/letsheat" for (Prod Mode/with folder)
export const apiUrl = backendUrl + apiPath + "/";
export const socketNameSpace = "/auth";
export const socketPath = "/socket.io";
export const jwtAuthorization = "Bearer ";            // "JWT " or "Bearer "
export const pathImageUrl = backendUrl + "/uploads/avatars/";
export const pathPdfUrl = backendUrl + "/uploads/files/";
export const pathInvoicePdfUrl = backendUrl + "/invoices/";
export const urlPostcodeApi = 'https://api.ideal-postcodes.co.uk';
export const urlPostcodeAutoComplete = '/v1/autocomplete/addresses?query=';
export const urlPostcodeKey = 'api_key=ak_kzffn7h9QNgqLhGEMrB3prQjeLOcJ';
export const dialCode = '+44';
export const appendPassword = 'PQ';
export const urlUploadImages = "https://boilers.letsheat.co.uk/images/uploads/";
export const urlQuote = "https://boilers.letsheat.co.uk/quote/results/";
export const imageTypeID = 42;

//////////////////////// URLS For Networks  /////////////////////////
export const urlLogin = "auth/login";
export const urlRegister = "auth/register";
export const urlCities = "cities/";
export const urlCreateCity = urlCities + "create";
export const urlUpdateCity = urlCities + "update/";
export const urlManufacturers = "manufacturers/";
export const urlCreateManufacturer = urlManufacturers + "create";
export const urlUpdateManufacturer = urlManufacturers + "update/";
export const urlFuelTypes = "fuelTypes/";
export const urlCreateFuelType = urlFuelTypes + "create";
export const urlUpdateFuelType = urlFuelTypes + "update/";
export const urlBoilerTypes = "boilerTypes/";
export const urlCreateBoilerType = urlBoilerTypes + "create";
export const urlUpdateBoilerType = urlBoilerTypes + "update/";
export const urlProducts = "products/";
export const urlCreateProduct = urlProducts + "create";
export const urlUpdateProduct = urlProducts + "update/";
export const urlProductSize = urlProducts + "sizes/";
export const urlUpdateProductSize = urlUpdateProduct + "sizes/";
export const urlProductPrices = urlProducts + "prices/";
export const urlUpdateProductPrice = urlUpdateProduct + "prices/";
export const urlProductPricePostcodes = urlProductPrices + "postcodes/";
export const urlUpdateProductPricePostcode = urlUpdateProductPrice + "postcodes/";
export const urlProductAddons = urlProducts + "addons/";
export const urlUpdateProductAddon = urlUpdateProduct + "addons/";
export const urlProductImages = urlProducts + "images/";
export const urlCreateProductImage = urlCreateProduct + "/images";
export const urlSizes = "sizes/";
export const urlCreateSize = urlSizes + "create";
export const urlUpdateSize = urlSizes + "update/";
export const urlPrices = "prices/";
export const urlCreatePrice = urlPrices + "create";
export const urlUpdatePrice = urlPrices + "update/";
export const urlPostcodes = "postcodes/";
export const urlCreatePostcode = urlPostcodes + "create";
export const urlUpdatePostcode = urlPostcodes + "update/";
export const urlCitiesPostcodes = urlCities + urlPostcodes;
export const urlPostCodePrices = "postCodePrices";
export const urlCreatePostCodePrice = urlPostCodePrices + "/create";
export const urlUpdatePostCodePrice = urlPostCodePrices + "/update/";
export const urlAddons = "addons/";
export const urlCreateAddon = urlAddons + "create";
export const urlUpdateAddon = urlAddons + "update/";
export const urlAddonProducts = "addonProducts";
export const urlCreateAddonProduct = urlAddonProducts + "/create";
export const urlUpdateAddonProduct = urlAddonProducts + "/update/";
export const urlQuestions = "questions/";
export const urlCreateQuestion = urlQuestions + "create";
export const urlUpdateQuestion = urlQuestions + "update/";
export const urlCategories = "categories/";
export const urlCreateCategory = urlCategories + "create";
export const urlUpdateCategory = urlCategories + "update/";
export const urlUsers = "users/";
export const urlUsersBasedRole = urlUsers + "roles/";
export const urlUpdateUser = urlUsers + "update/";
export const urlEngineers = urlUsers + "engineers/";
export const urlSubjects = "subjects/";
export const urlCreateSubject = urlSubjects + "create";
export const urlUpdateSubject = urlSubjects + "update/";
export const urlContacts = "contacts/";
export const urlUpdateContact = urlContacts + "update/";
export const urlInstallQuestions = "installQuestions/";
export const urlCreateInstallQuestion = urlInstallQuestions + "create";
export const urlUpdateInstallQuestion = urlInstallQuestions + "update/";
export const urlImageTypes = "imageTypes/";
export const urlCreateImageType = urlImageTypes + "create";
export const urlUpdateImageType = urlImageTypes + "update/";
export const urlOrderImageType = urlImageTypes + "orders";
export const urlCoupons = "coupons/";
export const urlCreateCoupon = urlCoupons + "create";
export const urlUpdateCoupon = urlCoupons + "update/";
export const urlAvailableInstallations = "availableInstallations";
export const urlCreateAvailableInstallation = urlAvailableInstallations + "/create";
export const urlUpdateAvailableInstallation = urlAvailableInstallations + "/update/";
export const urlJobCategories = "localServices/jobCategories";
export const urlCreateJobCategory = urlJobCategories + "/create";
export const urlUpdateJobCategory = urlJobCategories + "/update/";
export const urlCustomers = "localServices/customers/";
export const urlCreateCustomer = urlCustomers + "create";
export const urlUpdateCustomer = urlCustomers + "update/";
export const urlServices = "localServices/services";
export const urlCreateService = urlServices + "/create";
export const urlUpdateService = urlServices + "/update/";
export const urlOrders = "orders/";
export const urlCreateOrder = urlOrders + "manual/create";
export const urlUpdateOrder = urlOrders + "update/";
export const urlShowOrder = urlOrders + "show/";
export const urlQuotes = "quotes/";
export const urlSkills = "skills";
export const urlCreateSkill = urlSkills + "/create";
export const urlUpdateSkill = urlSkills + "/update/";
export const urlProfiles = "profile/";
export const urlCreateProfile = urlProfiles + "create";
export const urlUpdateProfile = urlProfiles + "update/";
export const urlUpdateApproveProfile = urlUpdateProfile + "approve/";
export const urlAvailableWorks = urlProfiles+"available/";
export const urlCreateAvailableWork = urlAvailableWorks + "create";
export const urlAssignEngineers = "assignEngineers";
export const urlCreateAssignEngineer = urlAssignEngineers + "/create";
export const urlUpdateAssignEngineer = urlAssignEngineers + "/update/";
export const urlInstallAnswers = "installAnswers";
export const urlCreateInstallAnswer = urlInstallAnswers + "/create";
export const urlUpdateInstallAnswer = urlInstallAnswers + "/update/";
export const urlImageOrders = "imageOrders/";
export const urlCreateImageOrder = urlImageOrders + "create";
export const urlNoteOrders = "noteOrders/";
export const urlCreateNoteOrder = urlNoteOrders + "create";
export const urlUpdateNoteOrder = urlNoteOrders + "update/";
export const urlPlans = "plans/";
export const urlCreatePlan = urlPlans + "create";
export const urlUpdatePlan = urlPlans + "update/";
export const urlOptions = "options/";
export const urlCreateOptions = urlOptions + "create";
export const urlUpdateOptions = urlOptions + "update/";
export const urlEmailTemplates = "emailTemplates/";
export const urlCreateEmailTemplate = urlEmailTemplates + "create";
export const urlUpdateEmailTemplate = urlEmailTemplates + "update/";
export const urlEmailOrders = "emailOrders/";
export const urlShowEmailOrders = urlEmailOrders + "show/";
export const urlCreateEmailOrder = urlEmailOrders + "create";
export const urlPlanServices = "planServices/";
export const urlCreatePlanService = urlPlanServices + "create";
export const urlUpdatePlanService = urlPlanServices + "update/";
export const urlPlanUsers = "planUsers/";
export const urlCreatePlanUser= urlPlanUsers + "create";
export const urlUpdatePlanUser = urlPlanUsers + "update/";
export const urlAppliances = "appliances/";
export const urlCreateAppliance= urlAppliances + "create";
export const urlUpdateAppliance = urlAppliances + "update/";
export const urlRoles = "roles/";
export const urlCreateRole = urlRoles + "create";
export const urlUpdateRole = urlRoles + "update/";
export const urlPermissions = "permissions/";
export const urlCreatePermission = urlPermissions + "create";
export const urlUpdatePermission = urlPermissions + "update/";
export const urlNoteProfiles = "noteProfiles/";
export const urlCreateNoteProfile = urlNoteProfiles + "create";
export const urlUpdateNoteProfile = urlNoteProfiles + "update/";
export const urlStatistics = "statistics/";

// Tags for insert words in mails
export const tagOrderID = "#orderID";

// Roles Slugs
export const allRoles = "all";
export const roleCustomers = "customer";
export const roleEngineers = "engineer";
export const roleAdmins = "admin";