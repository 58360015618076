import Firebase from 'firebase'

const firebaseApp = Firebase.initializeApp({
    apiKey: "AIzaSyB3CbG5nY1KZaxiFwad-4JuBz9ROuTM6cs",
    authDomain: "let-s-heat.firebaseapp.com",
    projectId: "let-s-heat",
    storageBucket: "let-s-heat.appspot.com",
    messagingSenderId: "593071150384",
    appId: "1:593071150384:web:b25c132c4dd805cb2e64f5",
    measurementId: "G-CV90Z73BBQ"
});


// const firestore = firebaseApp.firestore();


// firestore.settings({ timestampsInSnapshots: true });

export default firebaseApp
