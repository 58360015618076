import axios from 'axios';
import {apiUrl} from "@/config";

class Networks {
    constructor() {
        // this.mapConfig = {headers: {'Content-type': 'application/json'}};
    }

    fetchFromWeb(url, operationType = 0, data, isFormData = false) {
        this.mapConfig = {headers: {'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'}};
        if (operationType === 0) {
            return axios.get(apiUrl + url);
        } else if (operationType === 1) {
            return axios.post(apiUrl + url, data, this.mapConfig);
        } else if (operationType === 2) {
            return axios.put(apiUrl + url, data, this.mapConfig);
        } else if (operationType === 3) {
            return axios.delete(apiUrl + url, data, this.mapConfig);
        } else {
            return null;
        }
    }
}

export default new Networks();
