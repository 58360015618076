import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from "vuetify/es5/locale/en";
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {directives: {ripple}});
Vuetify.config.silent = true;
Vue.use(Vuetify);

export default new Vuetify({
    rtl: false,
    lang: {
        locales: {en},
        current: 'en',
    },
    theme: {
        options: {
            themeCache: {
                get: key => localStorage.getItem(key),
                set: (key, value) => localStorage.setItem(key, value),
            },
        },
    },

});
